//Convert number with DB unit to user set unit
//
//风机风量 voloum flow rate [m^3/h]
function VFlow(num, unit)
{
    var vf;
    switch (unit)
    {
        case "m^3/s":
            vf = num / 3600;
            break;
        case "m^3/h":
            vf = num;
            break;
        case "m^3/min":
            vf = num / 60;
            break;
        case "CFM":
        case "ft^3/min":
            vf = num / 1.699;
            break;                  
    }
    return vf;
}

function Press(num, unit)
{
    var p;
    switch(unit)
    {
        case "Pa":
            p = num;
            break;
        case "psi":
            p = num / 6.894757e3;
            break;
        case "kPa":
        case "KPa":
            p = num / 1.0e3;
            break;
        case "MPa":
            p = num / 1.0e6;
            break;
        case "bar":
            p = num / 1.0e5;
            break;
        case "atm.":
            p = num / 1.01325e5;
            break;              
        case "torr":
            p = num / 133.322;
            break;
        case "mmH20":
            p = num / 9.806405;
            break;
        case "in.Hg":
            p = num / 3386.379;
            break;
        case "lbm/ft^2":
            p = num / 47.88;
            break;
    }
    return p;
}

//风压 air pressure drop [Pa]
function DPair(num, unit){
    return Press(num, unit);
}
// (排气)压力 pressure [MPa]
function Pressure(num, unit){
    return Press(num*1.0e6, unit);
}
// 冷媒压降 pressure drop [KPa]
function DPref(num, unit){
    return Press(num*1.0e3, unit);
}
// 温度 Temperature [℃]
function Temp(num, unit)
{
    var t;
    switch(unit)
    {
        case "K":
            t = num + 273.15;
            break;
        case "℃":
            t = num;
            break;
        case "℉":
            t = 1.8 * num + 32;
            break;
        case "R":
            t=1.8 * num + 491.67;
            break;
    }
    return t;
}
// 温差（过冷度） Temperature diff [℃]
function DTemp(num, unit)
{
    var t;
    switch(unit)
    {
        case "K":
        case "℃":
            t = num;
            break;
        case "℉":
        case "R":
            t=1.8 * num;
            break;
    }
    return t;
}
// 风速 velocity [m/s]
function Vel(num, unit)
{
    var v;
    switch (unit)
    {
        case "m/s":
            v = num;
            break;
        case "ft/s":
            v = num * 3.28084;
            break;
        case "ft/min":
            v = num * 196.848;
            break;
        case "in/s":
            v = num *39.37;
            break;                  
    }
    return v;
}

//长度 length [mm]
function Length(num, unit)
{
    var L;
    switch(unit)
    {
        case "mm":
            L = num;
            break;
        case "in":
            L = num / 25.4;
            break;
        case "ft":
            L = num / 304.8;
            break;
        case "cm":
            L = num / 10;
            break;
        case "m":
            L = num / 1.0e3;
            break;
    }
    return L;
}


export {
	Temp,
	Pressure,
	DTemp,
	Vel
}